export const GET_REDEEM = "GET_REDEEM";
export const GET_SETTING = "GET_SETTING";
export const ACCEPT_REDEEM = "ACCEPT_REDEEM";

export const GET_MY_REDEEM = "GET_MY_REDEEM";
export const OPEN_NEW_REDEEM_DIALOG = "OPEN_NEW_REDEEM_DIALOG";
export const CLOSE_NEW_REDEEM_DIALOG = "CLOSE_NEW_REDEEM_DIALOG";

export const NEW_REDEEM_CREATE = "NEW_REDEEM_CREATE";
export const UPDATE_REDEEM_AGENCY = "UPDATE_REDEEM_AGENCY";

